import {
  Box, Container, Flex,
  Text,
  Wrap,
} from '@chakra-ui/react';
import MainLayout from '@src/components/layouts/main';
import { useDeliveryPerformanceKPI } from '@src/modules/performance/context/map-api-hooks';
import {
  useBridgeRenewalsAndNewBridges,
  useNewAndImprovedRoads,
  usePavementRehabilitation,
  usePavementResurfacing,
  useUnsealedRoadMetalling,
  useCulvertDrainageRenewals,
  useKerbAndChannelDrainageRenewals,
  useOtherDrainageRenewals,
  useRoadMaintenanceCost,
} from '@src/modules/performance/context/reports-api-hooks';
import { useRef, useState } from 'react';
import { useLocation } from 'react-router-dom';
import Pagination from '../../components/Pagination';
import ReportCard from '../../components/ReportCard';
import InfoContentCollapse from './components/information/InfoContentCollapse';
import InfoScroller from './components/information/InfoScroller';
import SLBarChart from './components/selfloading/SLBarChart';
import html from './help/DeliveryPerformance.html';
import SLDualAxisBarLineChart from './components/selfloading/SLDualAxisBarLineChart';
import HeaderTrafficLight from './components/selfloading/HeaderTrafficLight';

const subheader = 'Works Completed';

const twoLineChartColours = [
  'blue', 'red', 'grey',
];

const barChartColours = [
  'seagreen', 'orange',
];

// Text mappings still to come
const mapValueToText = (value) => {
  const text = [
    '',
  ];
  return text[value * 0];
};

function DeliveryPerformance() {
  const { pathname } = useLocation();
  const reportUrl = pathname.split('/').pop();
  const deliveryPerformanceKPI = useDeliveryPerformanceKPI();
  const pavementRehabilitation = usePavementRehabilitation();
  const pavementResurfacing = usePavementResurfacing();
  const unsealedRoadMetalling = useUnsealedRoadMetalling();
  const newAndImprovedRoads = useNewAndImprovedRoads();
  const bridgeRenewalsAndNewBridges = useBridgeRenewalsAndNewBridges();
  const culvertDrainageRenewals = useCulvertDrainageRenewals();
  const kerbAndChannelDrainageRenewals = useKerbAndChannelDrainageRenewals();
  const otherDrainageRenewals = useOtherDrainageRenewals();
  const roadMaintenanceCost = useRoadMaintenanceCost();
  // this controls the expansion of the help section
  const [index, setIndex] = useState(null);

  const helpSectionRef = useRef(null);
  // triggered when the user clicks on the help icon in the title
  const expandHelpSection = () => {
    setIndex(0);
  };

  // Mangle data to work with Marcel's chart components
  const pavementRehabilitationChartData = ({
    categories: pavementRehabilitation?.data?.categories || [],
    values: pavementRehabilitation?.data?.values?.map((data, idx) => ({ data, id: idx === 2 ? 'right' : 'left' })) || [],
    lockYear: pavementRehabilitation?.data?.lockYear,
  });
  const pavementResurfacingChartData = ({
    categories: pavementResurfacing?.data?.categories || [],
    values: pavementResurfacing?.data?.values?.map((data, idx) => ({ data, id: idx === 2 ? 'right' : 'left' })) || [],
    lockYear: pavementResurfacing?.data?.lockYear,
  });
  const unsealedRoadMetallingChartData = ({
    categories: unsealedRoadMetalling?.data?.categories || [],
    values: unsealedRoadMetalling?.data?.values?.map((data, idx) => ({ data, id: idx === 2 ? 'right' : 'left' })) || [],
    lockYear: unsealedRoadMetalling?.data?.lockYear,
  });
  const culvertDrainageRenewalsData = ({
    categories: culvertDrainageRenewals?.data?.categories || [],
    values: culvertDrainageRenewals?.data?.values?.map((data, idx) => ({ data, id: idx === 2 ? 'right' : 'left' })) || [],
    lockYear: culvertDrainageRenewals?.data?.lockYear,
  });
  const kerbAndChannelDrainageRenewalsData = ({
    categories: kerbAndChannelDrainageRenewals?.data?.categories || [],
    values: kerbAndChannelDrainageRenewals?.data?.values?.map((data, idx) => ({ data, id: idx === 2 ? 'right' : 'left' })) || [],
    lockYear: kerbAndChannelDrainageRenewals?.data?.lockYear,
  });
  const otherDrainageRenewalsData = ({
    categories: otherDrainageRenewals?.data?.categories || [],
    values: otherDrainageRenewals?.data?.values?.map((data, idx) => ({ data, id: idx === 2 ? 'right' : 'left' })) || [],
    lockYear: otherDrainageRenewals?.data?.lockYear,
  });
  const roadMaintenanceCostData = ({
    categories: roadMaintenanceCost?.data?.categories || [],
    values: roadMaintenanceCost?.data?.values?.map((data, idx) => ({ data, id: idx === 2 ? 'right' : 'left' })) || [],
    lockYear: roadMaintenanceCost?.data?.lockYear,
  });

  return (
    <MainLayout backgroundColor="gray.50">
      <Container maxW="full">
        <Pagination currentReportUrl={reportUrl} />
        <ReportCard mt={2} pt={0}>
          <ReportCard.Header title="Delivery Performance" subtitle={subheader} info={<InfoScroller expandHelpSection={expandHelpSection} ref={helpSectionRef} />}>
            <Flex align="center">
              <Box mr={3}>
                <HeaderTrafficLight size="65px" endpointData={deliveryPerformanceKPI?.data?.value} fieldname="deliveryPerformance" mapText={mapValueToText} />
              </Box>
              <Box>
                <Text fontWeight="bold" color="gray">
                  Delivery Grade
                  <br />
                  as at
                  {' '}
                  {deliveryPerformanceKPI?.data?.lockYear}
                </Text>
              </Box>
            </Flex>
          </ReportCard.Header>
          <ReportCard.Body>
            <Wrap spacing={4}>
              <Box>
                <Text mb={5} textStyle="reportSectionHeader">Local Road Maintenance Activities (Roads)</Text>
                <Flex flexDirection="row">
                  <SLDualAxisBarLineChart
                    queryInfo={{ data: pavementRehabilitationChartData }}
                    title1="Pavement rehabilitation (lane kms)"
                    title2="&nbsp;"
                    labels={[
                      'Forecast',
                      'Achieved',
                      'Variance',
                    ]}
                    vertlabels={[
                      'Lane kms',
                      'Variance %',
                    ]}
                    chartType={['bar', 'bar', 'line']}
                    colours={twoLineChartColours}
                    unitprefix={['', '']}
                    unitpostfix={['kms', '%']}
                    precision={1}
                    width="auto"
                  />
                  <SLDualAxisBarLineChart
                    queryInfo={{ data: pavementResurfacingChartData }}
                    title1="Pavement resurfacing (lane kms)"
                    title2="&nbsp;"
                    labels={[
                      'Forecast',
                      'Achieved',
                      'Variance',
                    ]}
                    vertlabels={[
                      'Lane kms',
                      'Variance %',
                    ]}
                    chartType={['bar', 'bar', 'line']}
                    colours={twoLineChartColours}
                    unitprefix={['', '']}
                    unitpostfix={['kms', '%']}
                    precision={1}
                    width="auto"
                  />
                  <SLDualAxisBarLineChart
                    queryInfo={{ data: unsealedRoadMetallingChartData }}
                    title1="Unsealed road remetalling (km)"
                    title2="&nbsp;"
                    labels={[
                      'Forecast',
                      'Achieved',
                      'Variance',
                    ]}
                    vertlabels={[
                      'km',
                      'Variance %',
                    ]}
                    chartType={['bar', 'bar', 'line']}
                    colours={twoLineChartColours}
                    unitprefix={['', '']}
                    unitpostfix={['kms', '%']}
                    precision={1}
                    width="auto"
                  />
                </Flex>
              </Box>
              <Box>
                <Text mb={5} textStyle="reportSectionHeader">Local Road Maintenance Costs</Text>
                <Flex flexDirection="row">
                  <SLDualAxisBarLineChart
                    queryInfo={{ data: roadMaintenanceCostData }}
                    title1="Road maintenance cost / lane km ($/lane kms)"
                    title2="&nbsp;"
                    labels={[
                      'Approved',
                      'Claimed',
                      'Variance',
                    ]}
                    vertlabels={[
                      '$/lane kms',
                      'Variance %',
                    ]}
                    chartType={['bar', 'bar', 'line']}
                    colours={twoLineChartColours}
                    unitprefix={['', '']}
                    unitpostfix={['', '%']}
                    precision={1}
                    width="auto"
                  />
                </Flex>
                <Text mt={2} fontSize="xs">
                  Source: NZTA Data and Tools
                </Text>
              </Box>
            </Wrap>
            <Wrap spacing={4}>
              <Box>
                <Text mb={5} textStyle="reportSectionHeader">Local Road Maintenance Activities (Drainage)</Text>
                <Flex flexDirection="row">
                  <SLDualAxisBarLineChart
                    queryInfo={{ data: culvertDrainageRenewalsData }}
                    title1="Drainage renewals - culverts (m)"
                    title2="&nbsp;"
                    labels={[
                      'Forecast',
                      'Achieved',
                      'Variance',
                    ]}
                    vertlabels={[
                      'm',
                      'Variance %',
                    ]}
                    chartType={['bar', 'bar', 'line']}
                    colours={twoLineChartColours}
                    unitprefix={['', '']}
                    unitpostfix={['m', '%']}
                    precision={1}
                    width="auto"
                  />
                  <SLDualAxisBarLineChart
                    queryInfo={{ data: kerbAndChannelDrainageRenewalsData }}
                    title1="Drainage renewals - kerb & channel (m)"
                    title2="&nbsp;"
                    labels={[
                      'Forecast',
                      'Achieved',
                      'Variance',
                    ]}
                    vertlabels={[
                      'm',
                      'Variance %',
                    ]}
                    chartType={['bar', 'bar', 'line']}
                    colours={twoLineChartColours}
                    unitprefix={['', '']}
                    unitpostfix={['m', '%']}
                    precision={1}
                    width="auto"
                  />
                  <SLDualAxisBarLineChart
                    queryInfo={{ data: otherDrainageRenewalsData }}
                    title1="Drainage renewals - other (m)"
                    title2="&nbsp;"
                    labels={[
                      'Forecast',
                      'Achieved',
                      'Variance',
                    ]}
                    vertlabels={[
                      'm',
                      'Variance %',
                    ]}
                    chartType={['bar', 'bar', 'line']}
                    colours={twoLineChartColours}
                    unitprefix={['', '']}
                    unitpostfix={['m', '%']}
                    precision={1}
                    width="auto"
                  />
                </Flex>
              </Box>
              <Box>
                <Text mb={5} textStyle="reportSectionHeader">Other Works Completed</Text>
                <Flex flexDirection="row">
                  <SLBarChart
                    queryInfo={bridgeRenewalsAndNewBridges}
                    title1="Bridge renewals and new bridges (lane km)"
                    title2="&nbsp;"
                    labels={[
                      'New Bridges',
                      'Bridge Renewals',
                    ]}
                    colours={barChartColours}
                    unitprefix=""
                    unitpostfix=""
                    precision={1}
                  />
                  <SLBarChart
                    queryInfo={newAndImprovedRoads}
                    title1="New and improved roads (lane km)"
                    title2="&nbsp;"
                    labels={['New & improved roads']}
                    colours={barChartColours}
                    unitprefix=""
                    unitpostfix=""
                    precision={1}
                    hideLegend
                  />
                </Flex>
              </Box>
            </Wrap>
          </ReportCard.Body>
        </ReportCard>
        <InfoContentCollapse
          ref={helpSectionRef}
          title="Information Sources"
          html={html}
          index={index}
          setIndex={setIndex}
        />
      </Container>
    </MainLayout>
  );
}

export default DeliveryPerformance;
