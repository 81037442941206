import { useAuthenticatedApi } from '@transport-insights/uikit';
import { useConfig } from '@src/context/config';

export default () => {
  const { PERFORMANCE_API_URL } = useConfig();
  const api = useAuthenticatedApi(PERFORMANCE_API_URL);

  return {
    async getSafetyKpiData(rcaId, lockYear) {
      const encodedLockYear = lockYear.replace('/', '-');
      const url = `/safety/map-kpi/${rcaId}/${encodedLockYear}`;
      const res = await api.get(url);
      return res?.data || [];
    },
    async getAchievementKpiData(rcaId, lockYear) {
      const encodedLockYear = lockYear.replace('/', '-');
      const url = `/achievements/map-kpi/${rcaId}/${encodedLockYear}`;
      const res = await api.get(url);
      return res?.data || [];
    },
    async getDeliveryPerformanceKpiData(rcaId, lockYear) {
      const encodedLockYear = lockYear.replace('/', '-');
      const url = `/delivery/map-kpi/${rcaId}/${encodedLockYear}`;
      const res = await api.get(url);
      return res?.data || [];
    },
    async getActivityManagementKPI(rcaId, lockYear) {
      const encodedLockYear = lockYear.replace('/', '-');
      const url = `/activity-management/map-kpi/${rcaId}/${encodedLockYear}`;
      const res = await api.get(url);
      return res?.data || [];
    },
    async getCoInvestorAssuranceKPI(rcaId, lockYear) {
      const encodedLockYear = lockYear.replace('/', '-');
      const url = `/coinvestor/map-kpi/${rcaId}/${encodedLockYear}`;
      const res = await api.get(url);
      return res?.data || [];
    },
    async getServicePerformanceMapKPI(rcaId, lockYear) {
      const encodedLockYear = lockYear.replace('/', '-');
      const url = `/service-performance/map-kpi/${rcaId}/${encodedLockYear}`;
      const res = await api.get(url);
      return res?.data || [];
    },
    async getDataQualityKPI(rcaId, lockYear) {
      const encodedLockYear = lockYear.replace('/', '-');
      const url = `/data-quality-kpi/${rcaId}/${encodedLockYear}`;
      const res = await api.get(url);
      return res?.data || [];
    },
    async getNetworkCharacteristics(rcaId, lockYear) {
      const encodedLockYear = lockYear.replace('/', '-');
      const url = `/network-characteristics/map-kpi/${rcaId}/${encodedLockYear}`;
      const res = await api.get(url);
      return res?.data || [];
    },
    async getMapData(type, rcaId, lockYear) {
      const encodedLockYear = lockYear.replace('/', '-');
      const url = `/map-data/${type}/${rcaId}/${encodedLockYear}`;
      const res = await api.get(url);
      return res?.data || [];
    },
  };
};
