import {
  Box, Grid,
} from '@chakra-ui/react';
import { useConfig } from '@src/context/config';
import {
  SideNavigation, MainHeader, currentDatabaseSelector, selectedLockYearState,
} from '@transport-insights/uikit';
import { useRecoilValue } from 'recoil';

export default function MainLayout({ showSidebar = true, children, ...other }) {
  const db = useRecoilValue(currentDatabaseSelector);
  const lockYear = useRecoilValue(selectedLockYearState);
  const dbString = db?.id ? db.id.toString() : null;
  const { LEGACY_PMRT_URL, SETTINGS_API_URL } = useConfig();

  return (
    <>
      <MainHeader pmrtLegacyUrl={LEGACY_PMRT_URL} settingsApiUrl={SETTINGS_API_URL} loginUrl={process.env.REACT_APP_LOGIN_PATH} />
      <Grid
        templateColumns={{ base: 'minmax(0, auto) minmax(0, 1fr)', xl: 'minmax(0, 18rem) minmax(0, 1fr)' }}
      >
        {showSidebar && (
        <SideNavigation
          db={dbString}
          lockYear={lockYear}
          reactRouterApps={['reg-insights', 'tce']}
        />
        )}
        <Box
          as="main"
          role="main"
          py={4}
          {...other}
        >
          { children }
        </Box>
      </Grid>
    </>
  );
}
