import {
  Container, SimpleGrid, Heading,
  useDisclosure,
  HStack,
  VStack,
  Flex,
  Text,
  Box,
  Icon,
} from '@chakra-ui/react';
import MainLayout from '@src/components/layouts/main/MainLayout';
import LoadingSpinner from '@src/components/shared/LoadingSpinner';
import EmptyState from '@src/components/shared/EmptyState';
import { FiPlus } from 'react-icons/fi';
import { useState } from 'react';
import { useRecoilValue } from 'recoil';
import { selectedDatabaseIdState } from '@transport-insights/uikit';
import { useTemporaryTrafficManagementList } from '../../context/temporary-traffic-management-api-hooks';
import TtmCard from './TtmCard';
import TtmResultForm from './TtmResultForm';
import { QUARTERS } from './constants';

export default function TemporaryTrafficManagement() {
  const rcaId = useRecoilValue(selectedDatabaseIdState);
  const { data, isLoading, error } = useTemporaryTrafficManagementList();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [selectedQuarter, setSelectedQuarter] = useState(null);

  const errorStatus = error?.response?.status;

  // Get the current financial year
  const currentFinancialYear = (new Date().getMonth() >= 6) ? `${new Date().getFullYear()}/${(new Date().getFullYear() % 100) + 1}` : `${new Date().getFullYear() - 1}/${new Date().getFullYear() % 100}`;

  // Get the previous two financial years
  const previousFinancialYears = Array.from({ length: 2 }, (_, i) => currentFinancialYear.slice(0, 4) - (i + 1)).map((year) => `${year}/${(year % 100) + 1}`);

  // Work out what quarter we are currently in
  // Start from July and increment by 3 months
  const currentQuarter = QUARTERS.find((quarter) => quarter.id === Math.floor(((new Date().getMonth() + 6) % 12) / 3) + 1);

  // Extract the existing Financial Years from the data
  // and sort them in ascending order
  const dataYears = data ? [...new Set(data.map((item) => item?.quadLockYear))].sort() : [];

  // This function groups data by financial years and quarters, ensuring that:
  // 1. Entries for the current financial year are always included and marked as editable.
  // 2. If an entry for the current quarter in the current year doesn't exist, it creates a new one with default values.
  // 3. For past years, it adds entries for all quarters, marking them as editable only if no entries exist for future years.
  // 4. The final result is a sorted and grouped object by financial year, with each year containing an array of entries.
  const groupedData = [
    ...new Set([...dataYears, currentFinancialYear, ...previousFinancialYears]),
  ].reduce((acc, year, index, yearsArray) => {
    if (year === currentFinancialYear) {
      const existingItem = data?.find((item) => item.quadLockYear === year && item.quarter === currentQuarter.id);
      if (!existingItem) {
        acc.push({
          id: null,
          quadDatabaseId: rcaId,
          quadLockYear: year,
          quarter: currentQuarter.id,
          ttmPercentage: null,
          ttmCost: null,
          unattendedSiteInspections: null,
          completedSiteInspections: null,
          isEditable: true, // Current year entries are editable
        });
      } else {
        acc.push({
          ...existingItem,
          isEditable: true, // Existing entries for current year are editable
        });
      }
    } else {
      const futureYearsHaveEntries = yearsArray.slice(index + 1).some((y) => data?.some((item) => item.quadLockYear === y));
      acc = acc.concat(
        QUARTERS.map((quarter) => ({
          ...data?.find((item) => item.quadLockYear === year && item.quarter === quarter.id) || {
            id: null,
            quadDatabaseId: rcaId,
            quadLockYear: year,
            quarter: quarter.id,
            ttmPercentage: null,
            ttmCost: null,
            unattendedSiteInspections: null,
            completedSiteInspections: null,
          },
          isEditable: !futureYearsHaveEntries, // Editable if no future year has entries
        })),
      );
    }
    return acc;
  }, [])
    .sort((a, b) => b.quadLockYear.localeCompare(a.quadLockYear, undefined, { numeric: true, sensitivity: 'base' }))
    .reduce((acc, item) => {
      if (!acc[item.quadLockYear]) {
        acc[item.quadLockYear] = [];
      }
      acc[item.quadLockYear].push(item);
      return acc;
    }, {});

  const handleSelectQuarter = (item) => {
    setSelectedQuarter(item);
    onOpen();
  };

  if (errorStatus === 401 || errorStatus === 500 || errorStatus === 403) {
    return (
      <MainLayout>
        <EmptyState
          title="Access Denied"
          message={(
            <>
              <Text color="gray.400">You do not have permission to view this page.</Text>
              <Text color="gray.400">Please sign in or select a different RCA.</Text>
            </>
            )}
        />
      </MainLayout>
    );
  }

  if (isLoading || groupedData === undefined) {
    return (
      <MainLayout>
        <LoadingSpinner />
      </MainLayout>
    );
  }

  return (
    <MainLayout backgroundColor="gray.50">
      {selectedQuarter && (
        <TtmResultForm isOpen={isOpen} onClose={onClose} selectedQuarter={selectedQuarter} />
      )}
      <Container maxW="full" flexDirection="column" display="flex" height="100%" flexGrow={1}>
        <HStack mb={6} width="100%" justify="space-between">
          <Heading as="h1">Temporary Traffic Management</Heading>
        </HStack>
        {groupedData?.length === 0 ? (
          <EmptyState title="No Data" message="Sorry there is no data available for selected network or financial year" />
        ) : (
          <VStack align="flex-start" width="100%">
            {Object.keys(groupedData).map((year) => (
              <Box key={year} width="100%">
                <Heading as="h2" size="md" py={2} px={4} background="gray.100" borderRadius="full" display="inline-block" mb={2}>{year}</Heading>
                <SimpleGrid key={year} columns={4} spacing={4} width="100%" py={2}>
                  {groupedData[year].sort((a, b) => a.quarter - b.quarter)
                    .map((item, indx) => (
                      item.id ? (
                        <TtmCard key={indx} item={item} onEdit={() => handleSelectQuarter(item)} />
                      ) : (
                        <Flex
                          key={indx}
                          background="gray.100"
                          borderRadius="base"
                          padding="4"
                          flexDir="column"
                          justifyContent="center"
                          align="center"
                          onClick={() => handleSelectQuarter(item)}
                          cursor="pointer"
                          transition="all .2s ease-in-out"
                          color="gray.600"
                          border="2px solid"
                          borderColor="transparent"
                          _hover={{ borderColor: 'gray.300' }}
                        >
                          <Icon as={FiPlus} boxSize={10} mb={2} />
                          <Text fontWeight="bold" fontSize="md">
                            {QUARTERS.find((q) => q.id === item.quarter).label}
                          </Text>
                        </Flex>
                      )
                    ))}
                </SimpleGrid>
              </Box>
            ))}
          </VStack>
        )}
      </Container>
    </MainLayout>
  );
}
