import { selectedDatabaseIdState } from '@transport-insights/uikit';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { useRecoilValue } from 'recoil';
import { useToast } from '@chakra-ui/react';
import useTemporaryTrafficManagementApi from '../api/useTemporaryTrafficManagementApi';

export function useTemporaryTrafficManagementList() {
  const rcaId = useRecoilValue(selectedDatabaseIdState);
  const api = useTemporaryTrafficManagementApi();

  return useQuery(
    ['temporary-traffic-management', rcaId],
    async () => api.getTemporaryTrafficManagementList(rcaId),
  );
}

export const useCreateTtmResult = () => {
  const api = useTemporaryTrafficManagementApi();
  const queryClient = useQueryClient();
  const toast = useToast();

  return useMutation(
    (ttmResult) => api.createTtmResult(ttmResult).then(({ data }) => data),
    {
      onSuccess: () => {
        queryClient.invalidateQueries('temporary-traffic-management');
        toast({
          title: 'Success',
          description: 'Result was successfully created.',
          status: 'success',
          duration: 5000,
          isClosable: true,
        });
      },
      onError: (error) => {
        toast({
          title: 'Error',
          description: error.response.data.Message ? error.response.data.Message : 'Result could not be created. Please try again.',
          status: 'error',
          duration: 5000,
          isClosable: true,
        });
      },
    },
  );
};

export const useUpdateTtmResult = () => {
  const api = useTemporaryTrafficManagementApi();
  const queryClient = useQueryClient();
  const toast = useToast();

  return useMutation(
    (ttmResult) => api.updateTtmResult(ttmResult).then(({ data }) => data),
    {
      onSuccess: () => {
        queryClient.invalidateQueries('temporary-traffic-management');
        toast({
          title: 'Success',
          description: 'Result was successfully updated.',
          status: 'success',
          duration: 5000,
          isClosable: true,
        });
      },
      onError: (error) => {
        toast({
          title: 'Error',
          description: error.response.data.Message ? error.response.data.Message : 'Result could not be updated. Please try again.',
          status: 'error',
          duration: 5000,
          isClosable: true,
        });
      },
    },
  );
};
