import {
  Container, Grid, GridItem,
  Heading,
  SimpleGrid,
} from '@chakra-ui/react';
import { useRef, useState } from 'react';
import { useRecoilValue } from 'recoil';

import MainLayout from '@src/components/layouts/main';
import EmptyState from '@src/components/shared/EmptyState';
import {
  useNetworkAvailabilityKPI, useNetworkCharacteristicsKPI, useRoadNetworkUseKPI,
  useTerritorialActivityKPI,
} from '@src/modules/performance/context/reports-api-hooks';
import { selectedDatabaseIdState } from '@transport-insights/uikit';
import ActivityManagementWidget from './components/dashboard/ActivityManagementWidget';
import CoinvestorWidget from './components/dashboard/CoinvestorWidget';
import DeliveryWidget from './components/dashboard/DeliveryWidget';
import PublicTransportWidget from './components/dashboard/PublicTransportWidget';
import RCAStatsWidget from './components/dashboard/RCAStatsWidget';
import SLTextWidget from './components/dashboard/SLTextWidget';
import SafetyWidget from './components/dashboard/SafetyWidget';
import ServicePerformanceWidget from './components/dashboard/ServicePerformanceWidget';
import InfoContentCollapse from './components/information/InfoContentCollapse';
import InfoScroller from './components/information/InfoScroller';
import html from './help/Dashboard.html';
import AmenityConditionWidget from './components/dashboard/AmenityConditionWidget';
import DeliveryPerformanceWidget from './components/dashboard/DeliveryPerformanceWidget';

function Dashboard() {
  const networkAvailabilityKPI = useNetworkAvailabilityKPI();
  const territorialActivityKPI = useTerritorialActivityKPI();
  const networkCharacteristicsKPI = useNetworkCharacteristicsKPI();
  const roadNetworkUseKPI = useRoadNetworkUseKPI();
  const rcaId = useRecoilValue(selectedDatabaseIdState);

  // this controls the expansion of the help section
  const [index, setIndex] = useState(null);

  const helpSectionRef = useRef(null);
  // triggered when the user clicks on the help icon in the title
  const expandHelpSection = () => {
    setIndex(0);
  };

  return (
    <MainLayout backgroundColor="gray.50">
      <Container maxW="full">
        <Heading
          as="h1"
          mb={6}
          display="flex"
          alignItems="center"
        >
          Performance Dashboard
          <InfoScroller expandHelpSection={expandHelpSection} ref={helpSectionRef} />
        </Heading>
        {rcaId >= 1000
          && <EmptyState title="Not available for selected network" message="Please select a Council, NZTA, or DOC Roads." />}
        {rcaId < 1000
          && (
          <Grid
            templateColumns="1fr 200px"
            gap={4}
          >
            <GridItem>
              <SimpleGrid minChildWidth="200px" spacing={4}>
                <ActivityManagementWidget />
                <ServicePerformanceWidget />
                <CoinvestorWidget />
                <DeliveryWidget />
                <DeliveryPerformanceWidget />
                <AmenityConditionWidget />
                <SafetyWidget />
                <PublicTransportWidget />
                <SLTextWidget
                  queryInfo={territorialActivityKPI}
                  title="Territorial Activitiy"
                  subtext1="Population Growth"
                  subtext2={`(${territorialActivityKPI?.data?.averageYearRange} Annual Avg)`}
                  precision={1}
                  colour="black"
                  postfix="%"
                  link="/performance/dashboard/territorial-activity"
                  fieldName="percentage"
                />
                <SLTextWidget
                  queryInfo={networkCharacteristicsKPI}
                  title="Network Physical Characteristics"
                  subtext1="Network Length Growth"
                  subtext2={`(${networkCharacteristicsKPI?.data?.averageYearRange} Annual Avg)`}
                  precision={1}
                  colour="black"
                  postfix="%"
                  link="/performance/dashboard/network-physical-characteristics"
                  fieldName="percentage"
                />
                <SLTextWidget
                  queryInfo={roadNetworkUseKPI}
                  title="Road Network Use"
                  subtext1="VKT Growth"
                  subtext2={`(${roadNetworkUseKPI?.data?.averageYearRange} Annual Avg)`}
                  precision={1}
                  colour="black"
                  postfix="%"
                  link="/performance/dashboard/road-network-use"
                  fieldName="percentage"
                />
                <SLTextWidget
                  queryInfo={networkAvailabilityKPI}
                  title="Network Availability and Accessibility"
                  subtext1="Proportion Network Accessible"
                  subtext2="to Class 1 and 50MAX"
                  precision={1}
                  colour="black"
                  postfix="%"
                  link="/performance/dashboard/network-availability"
                  fieldName="value"
                />
              </SimpleGrid>
              <InfoContentCollapse
                ref={helpSectionRef}
                title="Information Sources"
                html={html}
                index={index}
                setIndex={setIndex}
              />
            </GridItem>
            <GridItem>
              <RCAStatsWidget />
            </GridItem>
          </Grid>
          )}
      </Container>
    </MainLayout>
  );
}

export default Dashboard;
