import { useAuthenticatedApi } from '@transport-insights/uikit';
import { useConfig } from '@src/context/config';

export default () => {
  const { PERFORMANCE_API_URL } = useConfig();
  const api = useAuthenticatedApi(PERFORMANCE_API_URL);

  return {
    async getTemporaryTrafficManagementList(rcaId) {
      const url = `/temporary-traffic-management/rca/${rcaId}`;
      const res = await api.get(url);
      return res?.data || [];
    },
    async createTtmResult(ttmResult) {
      const res = await api.post('/temporary-traffic-management', { ...ttmResult }, {
        responseType: 'json',
      });
      return res?.data || null;
    },
    async updateTtmResult(ttmResult) {
      const res = await api.put('/temporary-traffic-management', { ...ttmResult }, {
        responseType: 'json',
      });
      return res?.data || null;
    },
  };
};
