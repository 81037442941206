import {
  Flex,
  Box,
  Text,
  IconButton,
  Icon,
} from '@chakra-ui/react';
import { FiEdit3 } from 'react-icons/fi';
import { QUARTERS } from './constants';

export default function TtmCard({ item, onEdit }) {
  return (
    <Flex
      background="white"
      boxShadow="base"
      borderRadius="base"
      padding="4"
      flexDir="column"
      justifyContent="space-between"
    >
      <Box>
        <Flex justify="space-between" align="center" mb={4}>
          <Text fontSize="lg" fontWeight="bold">
            {QUARTERS.find((q) => q.id === item.quarter).label}
          </Text>
          {item.isEditable && (<IconButton aria-label="Edit" icon={<Icon as={FiEdit3} boxSize={5} />} size="md" variant="ghost" colorScheme="brand.orange" onClick={() => onEdit()} />)}
        </Flex>
        <Flex justify="space-between" align="center">
          <Text fontSize="sm">Cost</Text>
          <Text fontSize="sm" fontWeight="bold">
            $
            {item.ttmCost.toLocaleString()}
          </Text>
        </Flex>
        <Flex justify="space-between" align="center">
          <Text fontSize="sm">Cost % of NLTP funded/delivered projects</Text>
          <Text fontSize="sm" fontWeight="bold">
            {(item.ttmPercentage * 100).toFixed(2)}
            %
          </Text>
        </Flex>
        <Flex justify="space-between" align="center">
          <Text fontSize="sm">Total Site Inspections</Text>
          <Text fontSize="sm" fontWeight="bold">{item.completedSiteInspections}</Text>
        </Flex>
        <Flex justify="space-between" align="center">
          <Text fontSize="sm">Unattended Sites</Text>
          <Text fontSize="sm" fontWeight="bold">{item.unattendedSiteInspections}</Text>
        </Flex>
      </Box>
    </Flex>
  );
}
