import { forwardRef } from 'react';
import { Chart, Line } from 'react-chartjs-2';
import { emptyChart } from '@src/modules/performance/shared/chartPlugins';
import { ChartColours as COLOURS } from './ChartColours';

Chart.register([emptyChart]);

// eslint-disable-next-line react/display-name
const LineChart = forwardRef(({
  grouping, labels, datasets, colours, unitprefix, unitpostfix, width, height, hideLegend, precision, formatK, chartMin, chartMax,
}, ref) => {
  const cheight = height || 250;
  const cwidth = width || 250;

  let showLegend = true;
  if (hideLegend) {
    showLegend = false;
  }

  const data = {
    labels: grouping, // grouping on x axis
    datasets: [],
  };

  // autoformat unit to 'k' or 'm' if formatK flag is specified

  // find the max
  let maxValues = [];
  datasets.forEach((ds) => {
    maxValues.push(Math.max(...ds));
  });

  // change unit and divisor depending on largest value
  let actualPostfix = unitpostfix;
  let div = 1;
  if (formatK) {
    if (Math.max(...maxValues) >= 2000) {
      div = 1000;
      actualPostfix = 'K';
    }
    if (Math.max(...maxValues) >= 1000000) {
      div = 1000000;
      actualPostfix = 'M';
    }
  }

  maxValues = [];
  const minValues = [];

  // populate the datasets
  // PMRT-6341
  // For each dataset series, it checks if the series is identical to the previous series.
  // If a series is identical to the previous one, it increases the point size and line thickness for that series.
  // It then adds the processed data to the chart configuration and updates the max and min values for each series.

  datasets.forEach((ds, index) => {
    let isSameAsPrevious = false;

    // Check if the current series is the same as the previous series
    if (index > 0) {
      const previousSeries = datasets[index - 1];
      if (previousSeries && ds.length === previousSeries.length) {
        isSameAsPrevious = ds.every((val, i) => val === previousSeries[i]);
      }
    }

    data.datasets.push({
      label: labels[index],
      data: ds.map((d) => {
        if (d === null) return d;
        return d !== 0 ? d / div : d;
      }),
      borderColor: COLOURS[colours[index]], // line colour
      backgroundColor: COLOURS[colours[index]], // also sets the colour of the legend
      pointRadius: isSameAsPrevious ? 3 : 2, // change the dot size if the current series is the same as the previous
      borderWidth: isSameAsPrevious ? 3 : 2, // change the line thickness if the current series is the same as the previous
    });

    maxValues.push(Math.max(...ds.map((d) => (d !== 0 ? d / div : d))));
    minValues.push(Math.min(...ds.map((d) => (d !== 0 ? d / div : d))));
  });

  let decimalPrecision = precision != null ? precision : 0;

  if (Math.max(...maxValues) <= 10) {
    decimalPrecision = 1;
  }

  const options = {
    responsive: false,
    maintainAspectRatio: false,
    plugins: {
      tooltip: {
        enabled: true,
        callbacks: {
          label: (tooltipItem) => `${tooltipItem.dataset.label}: ${unitprefix}${parseFloat(tooltipItem.raw)
            .toLocaleString('en-NZ', { minimumFractionDigits: decimalPrecision + 1, maximumFractionDigits: decimalPrecision + 1 })}${actualPostfix}`,
        },
        // hide tooltips if there is no label
        filter: (tooltipItem) => tooltipItem.datasetIndex < labels.length,
      },
      legend: {
        display: showLegend,
        labels: {
          usePointStyle: true, // round dots on legend
          boxWidth: 4, // size of the dots
          boxHeight: 4,
          filter: (legendItem) => legendItem.datasetIndex < labels.length, // hide tooltips if there is no label
        },
        // position: 'bottom'
      },
      datalabels: {
        display: false,
      },
    },
    spanGaps: true,
    scales: {
      y: {
        position: 'right', // y-axis on the right
        ticks: {
          precision: decimalPrecision, // no decimals on y axis
          padding: 20, // spacing between ticks and y-values
          callback: (value) => `${unitprefix}${value.toLocaleString('en-NZ', { minimumFractionDigits: decimalPrecision, maximumFractionDigits: decimalPrecision })}${actualPostfix}` // add units to y axis values
          ,
        },
        grid: {
          drawBorder: false, // hide the y-axis bar
        },
      },
      x: {
        grid: {
          display: false, // hide the vertical grid lines
        },
      },
    },
  };

  // clamp our graph to a min and/or a max value
  // ensures percentage graphs don't show weird negative values
  if (chartMin !== null) {
    const min = Math.min(...minValues);
    if (min > chartMin) {
      // options.scales.y.suggestedMin = chartMin
    } else {
      // ensure the zero values are shown
      options.scales.y.min = chartMin;
    }
  }
  if (chartMax !== null) {
    const max = Math.max(...maxValues);
    if (max < chartMax) {
      // options.scales.y.suggestedMax = chartMax
    } else {
      options.scales.y.max = chartMax;
    }
  }

  return (
    <Line ref={ref} data={data} options={options} height={cheight} width={cwidth} />
  );
});

export default LineChart;
