import { useActivityManagementKPI } from '@src/modules/performance/context/map-api-hooks';
import councildata from '../../../map/CouncilData';
import { mapActivityToText } from '../../../map/shared/IndicatorText';
import { TrafficLightColours } from '../charts/ChartColours';
import TrafficLightWidget from './TrafficLightWidget';

function ActivityManagementWidget({ ...props }) {
  const { data: activityManagementData } = useActivityManagementKPI();
  const color = councildata.mapValueToColourRange(activityManagementData, councildata.colours.activity);
  const text = mapActivityToText(activityManagementData, null);
  return (
    <TrafficLightWidget
      title="Activity Management"
      subtext1={text}
      link="/performance/dashboard/activity-management"
      data={activityManagementData}
      color={TrafficLightColours[color]}
      {...props}
    />
  );
}

export default ActivityManagementWidget;
