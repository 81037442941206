import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Button,
  FormControl,
  FormLabel,
  Input,
  InputRightAddon,
  InputGroup,
  InputLeftAddon,
  FormErrorMessage,
  Text,
} from '@chakra-ui/react';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm } from 'react-hook-form';
import { useEffect } from 'react';
import { QUARTERS } from './constants';
import { useCreateTtmResult, useUpdateTtmResult } from '../../context/temporary-traffic-management-api-hooks';

const schema = yup.object().shape({
  ttmCost: yup
    .string()
    .required('Cost is required')
    .matches(/^[0-9,.\s]*$/, 'Invalid cost format')
    .test('is-money', 'Invalid cost format', (value) => !Number.isNaN(parseFloat(value.replace(/[^0-9.-]+/g, '')))),
  ttmPercentage: yup.number().typeError('Must be a number').required('TTM Percentage is required').min(0)
    .max(100, 'Percentage should not be more than 100'),
  completedSiteInspections: yup.number().typeError('Must be a number').required('Total Site Inspections is required').min(0),
  unattendedSiteInspections: yup.number().typeError('Must be a number').required('Total Unattended/Redundant Sites is required').min(0),
});

function formatAsMoney(value) {
  if (value === null || value === '') return '';
  const parts = value.toString().replace(/[^0-9.]+/g, '').split('.');
  parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ',');
  return parts.join('.');
}

function TtmResultForm({ isOpen, onClose, selectedQuarter }) {
  const {
    id, quadDatabaseId, quadLockYear, quarter, ttmPercentage, ttmCost, unattendedSiteInspections, completedSiteInspections,
  } = selectedQuarter;
  const {
    register,
    handleSubmit,
    reset,
    setValue,
    formState: { errors },
  } = useForm({
    defaultValues: {
      ttmPercentage,
      ttmCost: formatAsMoney(ttmCost),
      unattendedSiteInspections,
      completedSiteInspections,
    },
    resolver: yupResolver(schema),
  });
  const { mutateAsync: doCreateTtmResult, isLoading: isCreating } = useCreateTtmResult();
  const { mutateAsync: doUpdateTtmResult, isLoading: isUpdating } = useUpdateTtmResult();

  const modalTitle = id ? 'Update Result' : 'Add Result';
  const submitButtonLabel = id ? 'Update Result' : 'Save Result';

  // Format cost as money when input changes
  const handleCostChange = (e) => {
    const { value } = e.target;
    const formattedValue = formatAsMoney(value);
    setValue('ttmCost', formattedValue, { shouldValidate: true });
  };

  // Reset form when selected quarter changes
  useEffect(() => {
    reset({
      ttmPercentage: ttmPercentage * 100,
      ttmCost: formatAsMoney(ttmCost),
      unattendedSiteInspections,
      completedSiteInspections,
    });
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedQuarter]);

  const handleClose = () => {
    onClose();
  };

  const onSubmit = async (data) => {
    const formData = {
      ...data,
      ttmCost: parseFloat(data.ttmCost.replace(/[^0-9.-]+/g, '')),
      ttmPercentage: data.ttmPercentage / 100,
      quarter,
      quadLockYear,
      quadDatabaseId,
    };
    // If id exists we are updating an existing record
    // Otherwise we are creating a new record
    if (id) {
      formData.id = id;
      await doUpdateTtmResult(formData);
    } else {
      await doCreateTtmResult(formData);
    }

    onClose();
  };

  return (
    <Modal isOpen={isOpen} onClose={handleClose} size="md">
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>
          { modalTitle }
        </ModalHeader>
        <ModalCloseButton />
        <form onSubmit={handleSubmit(onSubmit)}>
          <ModalBody>
            <FormControl mb={4}>
              <FormLabel mb={0}>Financial Year</FormLabel>
              <Text fontSize="xl">{quadLockYear}</Text>
            </FormControl>
            <FormControl mb={4}>
              <FormLabel mb={0}>Quarter</FormLabel>
              <Text fontSize="xl">{QUARTERS.find((x) => x.id === quarter).label}</Text>
            </FormControl>
            <FormControl mb={4} isInvalid={!!errors.ttmCost}>
              <FormLabel>Cost $</FormLabel>
              <InputGroup width="50%">
                <InputLeftAddon>$</InputLeftAddon>
                <Input
                  type="text"
                  {...register('ttmCost')}
                  onChange={handleCostChange}
                />
              </InputGroup>
              <FormErrorMessage>{errors.ttmCost && errors.ttmCost.message}</FormErrorMessage>
            </FormControl>
            <FormControl mb={4} isInvalid={!!errors.ttmPercentage}>
              <FormLabel>Cost % of NLTP Funded/Delivered Projects</FormLabel>
              <InputGroup width="50%">
                <Input type="text" {...register('ttmPercentage')} textAlign="right" />
                <InputRightAddon>%</InputRightAddon>
              </InputGroup>
              <FormErrorMessage>{errors.ttmPercentage && errors.ttmPercentage.message}</FormErrorMessage>
            </FormControl>
            <FormControl mb={4} isInvalid={!!errors.completedSiteInspections}>
              <FormLabel>Total Site Inspections</FormLabel>
              <Input width="25%" type="number" {...register('completedSiteInspections')} />
              <FormErrorMessage>{errors.completedSiteInspections && errors.completedSiteInspections.message}</FormErrorMessage>
            </FormControl>
            <FormControl isInvalid={!!errors.unattendedSiteInspections}>
              <FormLabel>Total Unattended/Redundant Sites</FormLabel>
              <Input width="25%" type="number" {...register('unattendedSiteInspections')} />
              <FormErrorMessage>{errors.unattendedSiteInspections && errors.unattendedSiteInspections.message}</FormErrorMessage>
            </FormControl>
          </ModalBody>
          <ModalFooter>
            <Button colorScheme="gray" mr={3} onClick={handleClose}>
              Cancel
            </Button>
            <Button colorScheme="orange" type="submit" isLoading={isCreating || isUpdating}>
              {submitButtonLabel}
            </Button>
          </ModalFooter>
        </form>
      </ModalContent>
    </Modal>
  );
}

export default TtmResultForm;
